import { NgModule } from '@angular/core';
import {
  MAT_DATE_LOCALE,
  MatDateFormats,
  MAT_DATE_FORMATS,
  DateAdapter,
} from '@angular/material/core';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { zhTW } from 'date-fns/locale';

export const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'yyyy/MM/dd',
  },
  display: {
    dateInput: 'yyyy/MM/dd',
    monthYearLabel: 'LLL uuuu',
    dateA11yLabel: 'PP',
    monthYearA11yLabel: 'LLLL uuuu',
  },
};

@NgModule({
  imports: [MatDialogModule],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: zhTW },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { width: '100%', maxWidth: '540px', hasBackdrop: true, disableClose: true },
    },
  ],
})
export class MaterialModule {}
