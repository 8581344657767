import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeZhHant from '@angular/common/locales/zh-Hant';
import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth, AuthModule } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { AppComponent } from './app/app.component';
import { APP_ROUTES } from './app/app.routes';
import { MaterialModule } from './app/shared/material/material.module';
import { environment } from './environments/environment';
registerLocaleData(localeZhHant);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: LOCALE_ID, useValue: 'zh-Hant' },
    provideRouter(APP_ROUTES),
    importProvidersFrom(
      HttpClientModule,
      BrowserAnimationsModule,
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideAuth(() => getAuth()),
      provideFirestore(() => getFirestore()),
      provideStorage(() => getStorage()),
      providePerformance(() => getPerformance()),
      AuthModule,
      MaterialModule
    ),
  ],
});
