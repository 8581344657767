import { Injectable } from '@angular/core';
import {
  Auth,
  authState,
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
  User as authUser,
} from '@angular/fire/auth';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { User } from './user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // 登入中的使用者資料，未登入則為null
  user$ = new BehaviorSubject<User>(null);

  constructor(private auth: Auth) {
    this.fetch();
  }

  signIn(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  signOut(): Promise<void> {
    return signOut(this.auth);
  }

  authState(): Observable<authUser | null> {
    return authState(this.auth);
  }

  private fetch() {
    authState(this.auth)
      .pipe(map((authUser) => this.authUserToUser(authUser)))
      .subscribe((user) => {
        this.user$.next(user);
      });
  }

  private authUserToUser(authUser?: authUser): User {
    if (!authUser) {
      return null;
    }
    return {
      id: authUser.uid,
      displayName: authUser.displayName,
      email: authUser.email,
      providerData: authUser.providerData[0],
    };
  }
}
